import React from 'react'
import PropTypes from 'prop-types'

import Icon from '@/components/Icon'
import { useTheme } from '@emotion/react'

import { HTML_POPUP_ID } from '@/components/Popup/config'
import { popupManager, popupDataManager } from '@/recoil/popup'

import { useSetRecoilState } from 'recoil'

import resolveAssetURL from '@/utils/resolveAssetURL'

import {
  Container,
  GridContainer,
  TopContainer,
  TopWrapper,
  Subtitle,
  Title,
  Text,
  Wrapper,
  Item,
  IconWrapper,
  ItemText,
  BottomContainer,
  Image
} from './style'

function Benefits ({
  title = '',
  subtitle = '',
  text = '',
  className,
  actions = [],
  action = null,
  items = [],
  popin=false
}) {

  const theme = useTheme()
  const setPopup = useSetRecoilState(popupManager)
  const setPopupData = useSetRecoilState(popupDataManager)

  const handleClick = (text, content, className) => {
    setPopupData({ title: text, content: content, className: className })
    setPopup(HTML_POPUP_ID)
  }

  return (
    <Container>
      <GridContainer>
        <TopContainer>
          <TopWrapper>
            <Subtitle>
              { subtitle }
            </Subtitle>
            <Title>
              { title }
            </Title>
            <Text>
              { text }
            </Text>
          </TopWrapper>
          { action }
        </TopContainer>
        <Wrapper>
          { items.map((item, i) => (
            <Item onClick={ () => popin ? handleClick(item.text, item.content, className) : ''} key={ i }>
              <IconWrapper>
                {/* <Icon size={ 50 } name={ item.icon }  color={  theme.colors.orange } /> */}
                <Image loading='lazy' src={ resolveAssetURL(item.icon) } alt={ item.alt || '' } />
              </IconWrapper>
              <ItemText>
                { item.text }
              </ItemText>
            </Item>
          )) }
        </Wrapper>
        <BottomContainer className={ actions.length > 1 ? 'many' : 'one' }>
          {
            !!actions.length && (
              actions.map((action) => (
                action
              ))
            )
          }
        </BottomContainer>
      </GridContainer>
    </Container>
  )
}

Benefits.propTypes = {
  // title: PropTypes.string,
  // subtitle: PropTypes.string,
  // text: PropTypes.string,
  actions: PropTypes.array,
  action: PropTypes.any,
  items: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.string,
    alt: PropTypes.string,
    text: PropTypes.string
  }))
}

export default Benefits
