import * as React from 'react'
import PropTypes from 'prop-types'

import { useRef, useState } from 'react'

import {
  Container,
  TitleWrapper,
  ContentWrapper,
  Cross,
  Title,
  TextWrapper,
  Text
} from './style'

const FaqItem = ({ item }) => {
  const [isActive, setIsActive] = useState(false)
  const [height, setHeight] = useState(0)
  const contentRef = useRef(null)

  const handleClick = () => {
    setIsActive(!isActive)
    setHeight(contentRef.current.clientHeight)
  }

  return (
    <Container>
      <TitleWrapper role='button' tabIndex={0} onClick={ handleClick } onKeyDown={(e) => e.key === 'Enter' && handleClick()}>
        <Title>
          { item.title }
        </Title>
        <Cross className={ `${isActive && 'open'}` } />
      </TitleWrapper>
      <ContentWrapper height={ isActive ? height : 0 }>
        <TextWrapper ref={ contentRef }>
          <Text>
            { item.text }
          </Text>
        </TextWrapper>
      </ContentWrapper>
    </Container>
  )
}

FaqItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string
  })
}

export default FaqItem
