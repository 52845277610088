import * as React from 'react'
import PropTypes from 'prop-types'

import FaqItem from './FaqItem'

import {
  Container,
  GridContainer,
  Wrapper,
  Title,
  Text,
  ListWrapper
} from './style'

const Faq = ({ title = '', text = '', items = [] }) => {
  return (
    <Container>
      <GridContainer>
        <Wrapper>
          <Title>
            { title }
          </Title>
          <Text>
            { text }
          </Text>
        </Wrapper>
        <ListWrapper>
          { items.map((item, i) => (
            <FaqItem item={ item } key={ i } />
          )) }
        </ListWrapper>
      </GridContainer>
    </Container>
  )
}

Faq.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string
  }))
}

export default Faq
