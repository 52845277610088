import styled from '@emotion/styled'

import { mediaMax, sectionTitle, sectionSubtitle } from '@/styles/mixins'

import {
  Subtitle as BlockSubtitle,
  Title as BlockTitle
} from '@/components/Block/Text/style'

import {
  Container as BenefitsContainer,
  Subtitle as BenefitsSubTitle,
  Title as BenefitsTitle,
  Item as Items
} from '@/components/Benefits/style'

import {
  Title as FaqTitle
} from '@/components/Faq/style'

import {
  Wrapper as CircularLabelButtonWrapper,
  Label as CircularLabelButtonLabel
} from '@/components/CircularLabelButton/style'

import {
  ButtonWrapper,
  ButtonLabel
} from '@/components/Button/style'

import { LeftWrapper, CenterWrapper } from '@/components/Block/TextImages/style'
// export const Wrapper = styled.div`
//   width: 100%;
//   // padding: 5vw;

//   .whr-item{
//     padding: 3rem 1rem;
//     border-top: 1px solid ${({ theme }) => theme.colors.lightGreen4};
//     border-bottom: 0px;
//     &:last-child{
//       // border-bottom: 1px solid ${({ theme }) => theme.colors.lightGreen4};
//     }

//     &:first-child{
//       border-top: 0px;
//     }
//   }

//   .whr-location, .whr-dept, .whr-date{
//     margin-top: 1.2rem;
//     font-size: 1rem;
//     span{
//       font-family: ${({ theme }) => theme.fonts.sansSerif};
//       font-weight: bold;
//     }
//   }
//   .whr-title{
//     margin: .75rem 0;
//     a{
//       color: ${({ theme }) => theme.colors.brown};
//       ${sectionSubtitle()}
//       font-size: 1.5rem;
//       text-decoration: none;
//     }
//   }
// `
export const Title = styled.p`
  ${sectionTitle()}
  margin-bottom: 1rem;
`

export const Container = styled.div`
  width: 100%;
  max-width: 85vw;
  margin: 0 auto;
  padding: 0 15px;
  margin-top: 12rem;
  margin-bottom: 17rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const Paragraph = styled.p`
  font-size: 1.5rem;
  line-height: 1.4rem;
  margin-bottom: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  color: ${({ theme }) => theme.colors.black};
  ${mediaMax.xs} {
    font-size: 1rem;
    max-width: 100%;
  }
`

export const ContainerCareerButton = styled.div`
  width: auto;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

// ORANGE = #dd8153
const ORANGE = '#dd8153'
// BLUE = #3f787b
// const BLUE = '#3f787b'

export const Wrapper = styled.div`
  width: 100%;


  .whr-item{
    padding: 3rem 0;
    border-top: 1px solid ${({ theme }) => theme.colors.lightGreen4};
    border-bottom: 0px;
    &:last-child{
      // border-bottom: 1px solid ${({ theme }) => theme.colors.lightGreen4};
    }

    &:first-child{
      border-top: 0px;
    }
  }

  .whr-location, .whr-dept, .whr-date{
    margin-top: 1.6rem;
    font-size: 1.2rem;
    span{
      font-family: ${({ theme }) => theme.fonts.sansSerif};
      font-weight: bold;
    }
  }
  .whr-title{
    margin: .75rem 0;
    a{
      ${sectionSubtitle()}
      font-size: 1.7rem;
      font-weight: bold;
      text-decoration: none;
      color: ${({ theme }) => theme.colors.lightGreen4};
    }
  }


  ${Items}{
    cursor: pointer;
  }
  ${LeftWrapper}{
    width: calc(100%/2.5) !important;
    img{
      object-fit: contain;
    }
  ${CenterWrapper}{
    width: 100% !important;
  }

  ${FaqTitle},
  ${BenefitsTitle},
  ${BlockTitle} {
    color: ${({ theme }) => theme.colors.lightGreen4};
  }

  ${BlockSubtitle},
  ${BenefitsSubTitle} {
    color: ${({ theme }) => theme.colors.brown};
  }

  ${BenefitsContainer} {
    ${CircularLabelButtonWrapper} {
      ${CircularLabelButtonLabel} {
        fill: ${({ theme }) => theme.colors.green};
      }

      .icon-arrow-right {
        path {
          fill: ${({ theme }) => theme.colors.green} !important;
        }
      }
    }
  }

  .icon-grape {
    path {
      fill: ${({ theme }) => theme.colors.lightGreen4} !important;
    }
  }

  ${ButtonWrapper} {
    &.green {
      background-color: ${({ theme }) => theme.colors.green};
      border: 1px solid ${({ theme }) => theme.colors.green};

      &:hover {
        ${ButtonLabel} {
          color: ${({ theme }) => theme.colors.green};
        }
        background-color: transparent;
      }
    }
  }
`
