import styled from '@emotion/styled'

import { sectionTitle, mediaMax} from '@/styles/mixins'

import WysiwygBase from '@/components/Wysiwyg'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  padding: 5rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightVanilla2};
  ${mediaMax.xs}{
    padding: 2.6rem 0;
  }
`

export const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-right: 2rem;
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  transition: all ease-out .3s;
  height: ${({ height }) => `${height}px`};

`

export const Title = styled.h3`
  padding-right: 2.5rem;
  ${sectionTitle};
  color: ${({ theme }) => theme.colors.greyText};
  font-size: 2.8rem;
  ${mediaMax.xs}{
    font-size: 2.6rem;
  }
`

export const Cross = styled.div`
  width: 25px;
  height: 25px;
  display: block;
  position: relative;

  &.open {
    &:after {
      transform: translate(-50%, -50%) rotate(180deg);
    }
  }

  &:after,
  &:before {
    content: '';
    width: 2.5rem;
    height: .2rem;
    background-color: ${({ theme }) => theme.colors.greyText};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all ease-in-out .3s;
  }

  &:after {
    transform: translate(-50%, -50%) rotate(90deg);
  }
`

export const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Text = styled(WysiwygBase)`
  width: 100%;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.grey};
  padding-top: 5rem;
  ${mediaMax.xs}{
    padding-top: 2.5rem;
  }
`
